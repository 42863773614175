import { GAME_TYPE } from 'enums/GameTypeEnum'
import { TOPIC_DROPDOWN_VALUES } from 'enums/TopicTypeEnum'

export const brainstormText = {
	battleShips: {
		dropdownValues: [
			{
				label: 'Reset dropdown',
				value: '',
			},
			'Marketing',
			'Sales',
			'Customer Experience',
			'Operations',
		],
		ideationPlaceholders: [
			'Enter your ideas here...',
			'Enter your anticipated competitor response here...',
			'Enter your ideas here...',
		],
		ideationTitles: [
			'How might your company respond to the above disruption?',
			'How might your competitors respond to your response?',
			'How might your company respond to your competitors’ response?',
		],
		ideationOne:
			'This is what you’ll create innovative ideas about, prompts will be customized based on your topic and company.',
		ideationTwo:
			'Imagine your business suddenly experiencing the following disruption.',
		ideationThree:
			'Enter your business’s and your competitors’ responses to your company experiencing the above scenario.',
		submittedIdeas: 'submitted ideas are only visible to game admins',
		dropdownLabel: 'Topic',
	},
	connectFour: {
		generateScenario: 'Generate scenario above',
		ideationOne:
			'This will be the focus of your ideation. Example scenario responses will be customized based on your company and problem statement.',
		ideationTwo:
			'Think about what comes to mind for each of the following prompts and apply those thoughts to solving the business problem in the next section.',
		ideationThree:
			'Based on the random prompts, jot down whatever comes to mind about the company problem.',
	},
	dressUp: {
		dropdownValues: [
			{
				label: 'Reset dropdown',
				value: '',
			},
			'Marketing',
			'Sales',
			'Customer Experience',
			'Operations',
		],
		generateScenario: 'Generate scenario above',
		ideaTextFieldPlaceholder: 'Enter your ideas here...',
		ideationOne:
			'This is what you’ll create innovative ideas about, prompts will be customized based on your topic and company.',
		ideationThree: 'Enter how your company could try these ideas.',
		ideationTwo:
			'Consider how the comparison industry operates to apply their strategies to your business in the next section.',
		/* eslint-disable max-len */
		submittedIdeas: 'submitted ideas are only visible to game admins',
		label: 'Optional - Enter business problem',
		dropdownLabel: 'Topic',
	},
	global: {
		warningText:
			"Don't worry, you're the only one who can see your submitted ideas!",
		alternateWarningText:
			'Submitted ideas are only visible to the game creator.',
		ideationRecommendationModal: {
			title: 'Ideation Recommendation',
			bodyText1:
				'Hey Innovator! We noticed you refreshed the GenAI Prompts a few times before documenting anything in the ideation section.',
			bodyText2:
				'Try and use the GenAI Prompt you’re given as inspiration for your ideation, and if you don’t like the prompt, you can edit it, or go back to page 1 of the game for a reminder on how to think about the facilitation technique.',
			primaryButton: 'GOT IT, LET’S IDEATE',
			secondaryButton: 'BACK TO PAGE 1',
		},
		ideasDropdown:
			'Unsure of how to apply the facilitation technique to your innovation topic?',
	},
	leapFrog: {
		popper: {
			header: 'What are random elements?',
			introText:
				'Random elements are unrelated from the scenario stimulate lateral thinking. For example...',
			bulletItems: [
				{
					title: 'Innovation Topic',
					details: 'Increasing sales for a retail company',
				},
				{ title: 'Random Element', details: 'Octopus' },
				{
					title: 'Association',
					details: 'Ocean, formal dining, suction power',
				},
				{
					title: 'Lateral Thinking',
					details:
						'The ocean reminds you of a  family cruise, which sparks an idea to create custom branded experiences - like cruise ships theme parks, or golf clubs.',
				},
			],
		},
	},
	musicalChairs: {
		ideationOne:
			'This is what you’ll create innovative ideas about, prompts will be customized based on your topic and company.',
		ideationThree:
			'Based on the challenged process, enter ideas on how the process outcome can still be completed;.',
		ideationTwo:
			'Consider these 4 process stages in relation to the innovation topic.',
	},
	publishedIdeas: {
		gameTypeFilterDropdownOptions: [
			{
				label: 'Reset dropdown',
				value: '',
			},
			{
				label: GAME_TYPE.SLOT_MACHINE.label,
				value: GAME_TYPE.SLOT_MACHINE.value,
			},
			{
				label: GAME_TYPE.MUSICAL_CHAIRS.label,
				value: GAME_TYPE.MUSICAL_CHAIRS.value,
			},
			{
				label: GAME_TYPE.BATTLE_SHIPS.label,
				value: GAME_TYPE.BATTLE_SHIPS.value,
			},
			{
				label: GAME_TYPE.DRESS_UP.label,
				value: GAME_TYPE.DRESS_UP.value,
			},
			{
				label: GAME_TYPE.CONNECT_FOUR.label,
				value: GAME_TYPE.CONNECT_FOUR.value,
			},
		],
	},
	roundRobin: {
		generateScenario: 'Generate scenario above',
		ideationTwo:
			'Consider how the context below can help improve the initial idea',
		ideationThree:
			'Based on your inputs from the prompts above, jot down whatever comes to mind about how the initial idea can be improved.',
		ideaDescription: `This is what you'll focus your innovation on`,
		label: 'Enter Brief Idea Description',
	},
	simonSays: {
		popper: {
			header: 'Example of how to use this GenAI Prompt!',
			introText:
				'Given the following customer type and connection point, here are some sample ideas you might come up with:',
			bulletItems: [
				{
					title: 'Customer Type',
					details:
						'Imani, a 35-year old digital nomad working abroad as a freelance graphic designeer',
				},
				{ title: 'Customer Connection Point', details: 'Flat tire' },
				{
					title: 'Potential Ideas',
					details:
						"If Imani can't drive to her cancer screening due to a flat tire, can we bring cancer screenings to her? Or host them in walkable areas? Or enable her to do screenings from home and mail in tests? Since she lives abroad, are there global standards for cancer testing and a global database to track results?",
				},
			],
		},
	},
	slotMachine: {
		dropdownValues: [
			{
				label: 'Reset dropdown',
				value: '',
			},
			...TOPIC_DROPDOWN_VALUES,
		],
		generateScenario: 'Generate scenario above',
		ideationOne:
			'This is what you’ll create innovative ideas about, prompts will be customized based on your topic and company.',
		ideationThree:
			'Based on the scenario, jot down whatever comes to mind about the innovation topic.',
		ideationTwo:
			'Consider the following customer personae. You’ll solve the customer problem in the next section.',
		randomElementTooltip: {
			body1: 'Random elements are intentionally unrelated from the scenario to stimulate lateral thinking about the scenario.',
			body2: 'Ask yourself what the random element reminds you of and then explore how those characteristics could contribute to the scenario at hand.',
			body3: 'If your scenario is focused on increasing sales for a consumer retail company, and you receive a random element of an octopus, you may think about the ocean, or formal dining, or suction power, etc.',
			body4: 'None of the ideas relate to an octopus, but they all derived their inspiration from an octopus.',
			// eslint-disable-next-line
			/* eslint-disable max-len */
			// body3: 'Rather, ask yourself what the random element reminds you of and maybe what those ideas remind you of, and then explore how those characteristics could contribute to the scenario at hand.',
			header1: 'Why We Include Random Elements',
			header2: 'Example',
			bullet1:
				'The ocean may remind you of a past family cruise, which could spark an idea to sell the retail brand at custom branded experiences - like cruise ships or theme parks or golf clubs.',
			bullet2:
				'Formal dining may remind you of a white glove experience, which could spark an idea to explore an exclusive sub-brand at a higher price point.',
			bullet3:
				'Suction power may remind you of a plumber, which could spark an idea to explore industry specific work attire and creating contracts with large home services providers.',
		},
		/* eslint-disable max-len */
		submittedIdeas: 'submitted ideas are only visible to game admins',
	},
	submittedIdeas: {
		gameTitleFilterDropdownValues: ['Game Name', 'N/A'],
		gameTypeFilterDropdownValues: [
			'Game Type',
			GAME_TYPE.SLOT_MACHINE.label,
			GAME_TYPE.MUSICAL_CHAIRS.label,
			GAME_TYPE.BATTLE_SHIPS.label,
			GAME_TYPE.DRESS_UP.label,
		],
		participantFilterDropdownValues: ['Author', 'N/A'],
		submittedIdeasSub:
			'Track your submissions + others submitted ideas to your games ',
		submittedIdeasTitle: 'Submitted Ideas',
		emptySubmittedIdeasSub: [
			'No ideas submitted yet, play a game to start submitting ideas!',
			'Want help submitting your first ideas?',
			'Schedule a live brainstorming session with expert facilitators.',
		],
		genAISuggestions: [
			'What are the top three themes from these ideas?',
			'What idea was repeated most frequently?',
			'Which of these ideas focus on marketing?',
		],
	},
}
