export const styles = {
	textField: {
		'& .MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'black',
				opacity: '23%',
			},
		},
	},
	chip: {
		paddingX: '0.5rem',
		color: 'black',
	},
	inputLabel: {
		color: 'black',
		opacity: '23%',
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		background: 'linear-gradient(180deg, #12BBF182 51%, #12BBF182 100%)',
	},
	typography: {
		title: {
			marginY: '2rem',
		},
		question: {
			color: 'black',
			opacity: 0.87,
		},
		terms: {
			color: 'black',
		},
		caution: {
			color: 'black',
			opacity: 0.6,
		},
	},
	proseMirror: {
		'.ProseMirror': {
			border: '1.5px solid var(--color-gray-4)',
		},
		'.ProseMirror-focused': {
			border: '2.5px solid var(--color-gray-4)',
		},
	},
	mainContainer: {
		flexDirection: 'column',
		gap: '2rem',
		flexGrow: '1',
	},
	ideationContainer: {
		minHeight: { xs: '15rem', sm: '20rem' },
	},
	richTextField: {
		background: '#E7F8FF',
		borderLeft: '1.5px solid rgba(0,0,0,0.23)',
		borderRight: '1.5px solid rgba(0,0,0,0.23)',
		borderTop: '1.5px solid rgba(0,0,0,0.23)',
	},
	buttonsContainer: {
		flexDirection: { xs: 'column-reverse', sm: 'row' },
		gap: '2rem',
	},
	termsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}
